import './ws_workflow.scss';

// External Libs
import _ from 'lodash';
import Axios from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Select from 'react-select'; // Or the appropriate import path based on your project

// Internal Components
import SessionExpire from '../../../../common/session_expire';
import { handleHttpRequestError } from '../../../../common/error';
import { debounceThrottle } from '../../../../common/helpers';
import { userHasPermission } from '../../../../common/permission';
import { setTokenHeader, removeIreqIdInitialNumber, downloadFile } from '../../../../common/functions';
import Modal from '../../../../components/modal/modal';
import { UserPermissionsContext } from '../../../../context/user_permission';
import Upload from '../wire_selection/upload';
import WireSelection from '../wire_selection/wire_selection';
import FabricationWorkflow from './fabrication_workflow/fabrication_workflow';
import { getPartTranslation } from '../wire_selection/wire_selection';

// Contants
import {
  ORIGINAL_SMARTWIRE_OPTIONS,
  WIRE_TYPE_OPTIONS,
  IR_TYPE_OPTIONS,
  PUSER_OPTION,
  FIELDS_TO_DISPLAY,
  WS_SELECTION_STATUS,
  WS_RECOMMENDATION_STATUS,
  WS_CLINICAL_REVIEW_STATUS,
  WS_REDESIGN_STATUS,
  WS_REDESIGN_FABRICATION_STATUS,
  WS_REDESIGN_REVIEW_STATUS,
  SHOW_NOTES_BY_STATUS,
  SHOW_REVISION_NOTES_BY_STATUSES,
  SHOW_GUIDE_BY_STATUS,
  SHOW_WO_BY_STATUS,
  PERMISSION_CODES,
  ORIGINAL_WIRE,
  WS_CONVERTING_STATUSES,
  WS_CONVERSION_STATUS,
  WS_CUSTOM_WO_STATUS,
  WS_CONVERSION_SUCCEEDED_STATUS,
  WS_COMPLETE_STATUS,
  WS_COMPLETE_FABRICATION_STATUS,
  WS_WIRE_FABRICATION_STATUS,
  WS_WIRE_FABRICATION_REINITIATED_STATUS,
  WS_WIRE_FABRICATION_RESOLVED_STATUS,
  ASSIGNED_TO_STATUSES,
  WS_MANUAL_CONVERSION_STATUS,
  WS_CONVERSION_FAILED_STATUS,
} from './constants';

//Redux
import { getDxfFileNameError, getWsGuideFileNameError, getCustomWoFileNameError } from '../../../../redux/reducers/bpp/wire_selection/wire_selection';
import { setDxfFileNameError, setWsGuideFileNameError, setCustomWoFileNameError } from '../../../../redux/actions/bpp/wire_selection/wire_selection';

class WSWorkflow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      all_original_wire: false,
      any_pusher: false,
      any_custom: false,
      all_original_smartwire_selected: false,
      all_ir_type_selected: false,
      all_wire_type_selected: false,
      all_files_uploaded: false,
      refresh: false,
      action_modal: '',
      recommendation_notes: '',
      revision_notes: '',
      ws_data: {},
      display_wire_selection: false,
      modal_in_progress_text: '',
      modal_in_progress: '',
      permitted_users: [],
      assigned_to: '',
      modal: null,
      upload_modal: null,
      custom_wo_file: null,
      case_ws: [],
      ir_wo_history: [],
    };
    this.onModalConfirm = this.onModalConfirm.bind(this);
    this.wsRemoveFile = this.wsRemoveFile.bind(this);
    this.handleAssignedToChange = this.handleAssignedToChange.bind(this);
    this.handleRecommendationNotesChange = this.handleRecommendationNotesChange.bind(this);
    this.debouncedUpdateRecommendationNotes = debounceThrottle(this.updateRecommendationNotes.bind(this), 700);
    this.handleRevisionNotesChange = this.handleRevisionNotesChange.bind(this);
    this.refreshWSWorkflow = this.refreshWSWorkflow.bind(this);
    this.setPermittedUsers = this.setPermittedUsers.bind(this);
    this.fileNameErrorTimeoutId = null;
  }

  /**
   * Translates the wire information based on the template type and manual process file ID.
   * @function
   * @param wire {object} - The wire object, containing template and ireq_manual_process_file_id information.
   * @returns {string} - The translated wire information, such as 'pn_custom', 'pn_universal', or null.
   */
  translateWire = (wire) => {
    const isRedesign = wire.ir_type === 'Redesign';
    const isOriginalCustom =
      wire.ir_type === 'Original Wire' && (wire.original_smartwire.includes('Custom') || wire.original_smartwire.includes('Experimental'));
    const isTemplate = wire.original_smartwire === 'Template' && wire.ir_type === 'Original Wire';

    const getTemplateTranslation = (template) => {
      const templateTranslations = {
        Standard: 'pn_standard',
        'No 4s': 'pn_no_4',
        'One 4': 'pn_one_4',
      };
      return template in templateTranslations ? templateTranslations[template] : '';
    };

    const translation_type_manual = isRedesign || isOriginalCustom ? 'pn_custom' : isTemplate ? `${getTemplateTranslation(wire.template)}` : null;
    const translation_manual = getPartTranslation(wire.ireq_details__ireq_parts__part_id, translation_type_manual);
    return translation_manual;
  };

  /**
   * Sets the list of permitted users and assigns a user based on data retrieved from the server.
   * @function
   * @param res {object} - The server response, which should contain a list of users.
   * @param ws_data {object} - Workspace data, containing the current workspace state.
   */
  setPermittedUsers = (res, ws_data) => {
    let assigned_to = '';
    let permitted_users = [];
    if (res && res.data && res.data.user_list && res.data.user_list.length > 0) {
      permitted_users = res.data.user_list
        .sort((a, b) => {
          if (a.user__first_name.toLowerCase() < b.user__first_name.toLowerCase()) {
            return -1;
          }
          if (a.user__first_name.toLowerCase() > b.user__first_name.toLowerCase()) {
            return 1;
          }
          return 0; // equal names
        })
        .map((user) => {
          return {
            label: user.user__first_name + ' ' + user.user__last_name,
            value: user.user__id,
          };
        });
      let assigned_to_id = ws_data.ws[0]?.ireireqassignment[0]?.assigned_to;
      assigned_to_id = assigned_to_id ? assigned_to_id : null;
      const assigned_to_label = permitted_users.find((user) => user.value === assigned_to_id)?.label || 'Unassigned';
      assigned_to = { label: assigned_to_label, value: assigned_to_id };
    }
    this.setState({ permitted_users, assigned_to });
  };

  /**
   * Makes a GET request to fetch the list of users who have a specific permission code, as dictated by the workspace data.
   * @function
   * @param ws_data {object} - Workspace data, containing the current workspace state.
   */
  getPermittedUsers = (ws_data) => {
    const user_type = 'Wire Selection Reviewer';
    const that = this;
    Axios.get(`/apiv3/permission/${user_type}/users_by_type`).then(function (res) {
      that.setPermittedUsers(res, ws_data);
    });
  };

  /**
   * Makes a GET request to fetch the list of users who have a specific role, then sets the permitted users.
   * @function
   * @param ws_data {object} - Workspace data, containing the current workspace state.
   * @param role {string} - The role to search for in the server.
   */
  getUsersByRole = (ws_data, role) => {
    const that = this;
    Axios.get(`/apiv3/permission/${role}/users_by_role`).then(function (res) {
      that.setPermittedUsers(res, ws_data);
    });
  };

  /**
   * Checks if any workspace in the workspace data array has a fabrication status.
   * @function
   */
  anyFabricationStatus = () => {
    return this.state?.ws_data?.ws?.some((item) => item.status === WS_WIRE_FABRICATION_STATUS);
  };

  /**
   * Updates the component state based on the current state of the items in the workspace.
   * @function
   */
  refreshButtonsStates = () => {
    const all_original_smartwire_selected = this.state.items.every((item) => item?.original_smartwire);
    const all_original_wire = this.state.items.every(
      (item) => item?.ir_type === ORIGINAL_WIRE && (item.manual_upload ? item?.ireq_manual_process_file__upload_data : true)
    );
    const all_ir_type_selected = this.state.items.every((item) => item?.ir_type);
    const any_pusher = this.state.items.some((item) => PUSER_OPTION.includes(item?.wire_type));
    const any_redesign = this.state.items.some((item) => item?.ir_type === 'Redesign');
    const any_original_custom = this.state.items.some((item) => item?.ir_type === 'Original Wire' && item?.original_smartwire.toLowerCase().includes('custom'));
    const any_custom = any_redesign || any_original_custom;
    const all_wire_type_selected = this.state.items.every((item) => item?.wire_type || item?.ir_type === ORIGINAL_WIRE);
    const all_files_uploaded = this.state.items.every((item) =>
      !(item.original_smartwire === 'Template' && item.ir_type === 'Original Wire')
        ? item?.ireq_manual_process_file__upload_data || item?.ireq_file__upload_data
        : true
    );
    this.setState({
      all_original_smartwire_selected,
      all_original_wire,
      all_ir_type_selected,
      any_pusher,
      all_wire_type_selected,
      all_files_uploaded,
      any_custom,
    });
  };

  /**
   * Refreshes the workspace workflow by making a GET request to fetch the workspace state, then updating the component state accordingly.
   * @function
   */
  refreshWSWorkflow = () => {
    this.resetFileNameError();
    const that = this;
    Axios.get(`/apiv3/ireqautomationstatus/${this.props.item_request.ireq_id}/ws`).then(function (res) {
      if (res && res.data && res.data.ws && res.data.ws.length > 0) {
        const ws_data = res.data;
        ws_data.ws.sort((a, b) => b.id - a.id);
        const status = ws_data.ws[0]['status'];
        const new_ws_workflow = ws_data.ws.some((item) => item.status === WS_SELECTION_STATUS);
        if (new_ws_workflow) {
          const case_ws = ws_data.case_ws;
          const ir_wo_history = ws_data.wo_history;
          const items = ws_data.ws_details
            .filter((item) => item.current_ws)
            .filter(({ group }) => group === 'smartwire')
            .map((item) => ({
              ...item,
              original_smartwire: item?.original_smartwire ? item?.original_smartwire : '',
              ir_type: item?.ir_type ? item?.ir_type : '',
              wire_type: item?.wire_type ? item?.wire_type : '',
              dxf_file: '',
            }));

          const recommendation_notes = ws_data.ws.find((item) => item.status === WS_RECOMMENDATION_STATUS)?.notes;
          let revision_notes = '';
          if (status === WS_REDESIGN_STATUS) {
            revision_notes = ws_data.ws.find((item) => item.status === WS_REDESIGN_STATUS)?.notes;
          }
          const currentRev = items.length > 0 ? items[0].wire_rev : 'A';
          const custom_wo_file = ws_data.ir_files?.find((file) => file.file_type === 'ws_custom_wo' && file.upload_data.includes(`/${currentRev}/`));

          const isRework = ws_data?.ws?.some((item) => item.status === WS_WIRE_FABRICATION_STATUS);
          items.map((item) => {
            const part_id = item['ireq_details__ireq_parts__part_id'];
            const showTemplateNoStatuses = [
              WS_REDESIGN_REVIEW_STATUS,
              WS_CUSTOM_WO_STATUS,
              WS_COMPLETE_STATUS,
              WS_WIRE_FABRICATION_STATUS,
              WS_COMPLETE_FABRICATION_STATUS,
              WS_WIRE_FABRICATION_REINITIATED_STATUS,
              WS_WIRE_FABRICATION_RESOLVED_STATUS,
            ];
            const showTemplateNo = showTemplateNoStatuses.includes(status)
              ? true
              : isRework && (item.status === 'Success' || item.course_of_action === 'Recut');
            const templateNo = showTemplateNo ? that.translateWire(item) : '';
            that.props.updateTemplateNo(part_id, templateNo);
            return null;
          });

          that.setState(
            {
              items: items,
              ws_data: ws_data,
              ws_automation_status: status,
              action_modal: '',
              upload_modal: null,
              modal_in_progress: false,
              recommendation_notes: recommendation_notes,
              revision_notes: revision_notes,
              remove_file: '',
              custom_wo_file: custom_wo_file,
              case_ws: case_ws,
              ir_wo_history: ir_wo_history,
            },
            () => {
              that.refreshButtonsStates();
              that.props.setWsStatus(status);
              const guide = that.getGuide();
              that.props.hasWsGuide(guide && !!(guide.ireq_manual_process_file__upload_data || guide.ireq_file__upload_data));
              if (status === WS_CUSTOM_WO_STATUS) {
                that.getUsersByRole(ws_data, 'TBD');
              } else {
                that.getPermittedUsers(ws_data);
              }
            }
          );
        } else {
          that.setState({
            ws_data: ws_data,
            display_wire_selection: true,
            action_modal: '',
            modal_in_progress: false,
            ws_automation_status: status,
          });
        }
      }
    });
  };

  componentDidMount() {
    this.refreshWSWorkflow();
  }

  /**
   * Checks if the file name error should be reset.
   * @returns {boolean} Returns true if the file name error should be reset, otherwise false.
   */
  shouldResetFileNameError = () => {
    const { dxf_file_name_error, ws_guide_file_name_error, custom_wo_file_name_error } = this.props;
    const { upload_modal } = this.state;
    return (dxf_file_name_error.case_id || dxf_file_name_error.format || ws_guide_file_name_error || custom_wo_file_name_error.error) && upload_modal;
  };

  /**
   * Lifecycle method called after the component updates.
   * @param {Object} prevProps - The previous props of the component.
   * @param {Object} prevState - The previous state of the component.
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.shouldResetFileNameError() && prevState.upload_modal !== null) {
      this.setState({ upload_modal: null });
      if (this.fileNameErrorTimeoutIdtId) clearTimeout(this.fileNameErrorTimeoutId);
    }
  }

  /**
   * Called immediately before a component is unmounted and destroyed.
   * It is a good place to perform any necessary cleanup, such as cancelling timers,
   * cancelling network requests, or cleaning up any subscriptions or event listeners.
   */
  componentWillUnmount() {
    if (this.fileNameErrorTimeoutId) clearTimeout(this.fileNameErrorTimeoutId);
  }

  /**
   * Renders the `WireSelection` component with specific props.
   * @function
   * @return {JSX} - JSX representing the `WireSelection` component.
   */
  displayPreviousWireSelection = () => {
    return (
      <WireSelection
        item_request={this.props.item_request}
        ireq_smartwires={this.props.ireq_smartwires}
        updateTemplateNo={this.props.updateTemplateNo}
        hasWsGuide={this.props.hasWsGuide}
        setWsStatus={this.props.setWsStatus}
        patient_info={this.props.patient_info}
        ws_data={this.state.ws_data}
      />
    );
  };

  /**
   * Updates the component state with the new value of revision notes input.
   * @function
   * @param event {object} - The event triggered when the revision notes input changes.
   */
  handleRevisionNotesChange(event) {
    this.setState({ revision_notes: event.target.value });
  }

  /**
   * Sends a PATCH request to update recommendation notes on the server.
   * @function
   * @param recommendation_notes {string} - The new recommendation notes.
   */
  updateRecommendationNotes(recommendation_notes) {
    setTokenHeader();
    const that = this;
    const data = {
      ireq_automation_status_id: this.state.ws_data.ws[0].id,
      notes: recommendation_notes,
    };
    Axios.patch(`/apiv3/ireqautomationstatus/${this.props.item_request.ireq_id}/ws/update_notes`, { ...data }).catch(function (err) {
      handleHttpRequestError(err, that);
    });
  }

  /**
   * Updates the component state with the new value of recommendation notes input and updates the server.
   * @function
   * @param event {object} - The event triggered when the recommendation notes input changes.
   */
  handleRecommendationNotesChange(event) {
    const recommendation_notes = event.target.value;
    this.setState({ recommendation_notes });
    this.debouncedUpdateRecommendationNotes(recommendation_notes);
  }

  /**
   * Updates assigned_to.
   * @function
   * @param assigned_to {string} - The ID of the assigned user
   */
  fetchUpdateAssignedTo(assigned_to) {
    setTokenHeader();
    const that = this;
    const data = {
      ireq_automation_status_id: this.state.ws_data.ws[0].id,
      assigned_to,
    };
    Axios.patch(`/apiv3/ireqautomationstatus/${this.props.item_request.ireq_id}/ws/update_assigned_to`, { ...data }).catch(function (err) {
      handleHttpRequestError(err, that);

      if (err && err.response && err.response.status === 409) {
        that.setState({
          action_modal: '',
          modal_in_progress: false,
        });
      }
    });
  }

  /**
   * Handles change in assigned_to state.
   * @function
   * @param assigned_to {string} - The ID of the assigned user
   */
  handleAssignedToChange(assigned_to) {
    this.setState({ assigned_to });
    this.fetchUpdateAssignedTo(assigned_to.value);
  }

  /**
   * Displays the current assignee.
   * @function
   * @return {JSX} - JSX for displaying the current assignee
   */
  displayAssignedTo(title) {
    const { permission } = this.getStatusConfig();

    return (
      this.state.assigned_to && (
        <div className="wire-selection-assignment">
          <label className="bold-text assignment-label">{title} Assigned to: </label>
          <UserPermissionsContext.Consumer>
            {(user_roles_and_permissions) => {
              return !permission || userHasPermission(permission, user_roles_and_permissions.permissions) ? (
                <Select
                  className="select-assign-to"
                  name="wire_selection_assignment"
                  value={this.state.assigned_to}
                  options={[{ label: 'Unassigned', value: null }, ...this.state.permitted_users]}
                  onChange={this.handleAssignedToChange}
                />
              ) : (
                <div>{this.state.assigned_to.label ? this.state.assigned_to.label : 'N/A'}</div>
              );
            }}
          </UserPermissionsContext.Consumer>
        </div>
      )
    );
  }

  /**
   * Updates the changed field of an item.
   * @function
   * @param item {object} - The item object
   * @param field {string} - The field to be updated
   * @param value {any} - The new value for the field
   */
  updateItemFieldChanged(item, field, value) {
    setTokenHeader();
    const that = this;
    const data = {
      ireq_wire_selection_id: item.id,
      fields: { [field]: value },
    };
    Axios.patch(`/apiv3/ireqautomationstatus/${this.props.item_request.ireq_id}/ws/update_field`, { ...data }).catch(function (err) {
      handleHttpRequestError(err, that);

      if (err && err.response && err.response.status === 409) {
        that.setState({
          action_modal: '',
          modal_in_progress: false,
        });
      }
    });
  }

  /**
   * Handles change in original_smartwire field of an item.
   * @function
   * @param selectedOption {object} - The selected option
   * @param wireName {string} - Wire name of the item
   */
  handleOriginalSmartwireChange = (selectedOption, wireName) => {
    const items = [...this.state.items];
    const index = this.getWireIndex(wireName);
    items[index] = { ...items[index], original_smartwire: selectedOption.value };
    const hasNoOriginalManualCustom = ['Manual Custom', 'Experimental'].includes(selectedOption.value) && !this.state.case_ws.includes(wireName);
    const clearIrType = (selectedOption.value === 'No Original Wire' || hasNoOriginalManualCustom) && items[index]?.ir_type === 'Original Wire';

    if (clearIrType) items[index].ir_type = null;
    this.setState({ items }, () => {
      this.refreshButtonsStates();
    });
    this.updateItemFieldChanged(items[index], 'original_smartwire', selectedOption.value);
    if (clearIrType) {
      this.updateItemFieldChanged(items[index], 'ir_type', null);
    }
  };

  /**
   * Handles change in ir_type field of an item.
   * @function
   * @param selectedOption {object} - The selected option
   * @param wireName {string} - Wire name of the item
   */
  handleIRTypeChange = (selectedOption, wireName) => {
    const items = [...this.state.items];
    const index = this.getWireIndex(wireName);
    items[index] = { ...items[index], ir_type: selectedOption.value };
    if (items[index]?.ir_type === ORIGINAL_WIRE) items[index].wire_type = null;
    this.setState({ items }, () => {
      this.refreshButtonsStates();
    });
    this.updateItemFieldChanged(items[index], 'ir_type', selectedOption.value);
    if (items[index]?.ir_type === ORIGINAL_WIRE) {
      this.updateItemFieldChanged(items[index], 'wire_type', null);
    }
  };

  /**
   * Handles change in wire_type field of an item.
   * @function
   * @param selectedOption {object} - The selected option
   * @param wireName {string} - Wire name of the item
   */
  handleWireTypeChange = (selectedOption, wireName) => {
    const index = this.getWireIndex(wireName);
    const items = [...this.state.items];
    items[index] = { ...items[index], wire_type: selectedOption.value };
    this.setState({ items }, () => {
      this.refreshButtonsStates();
    });
    this.updateItemFieldChanged(items[index], 'wire_type', selectedOption.value);
  };

  /**
   * Formats the value to be used in Select component.
   * @function
   * @param value {any} - The value to be formatted
   * @return {object} - The formatted value
   */
  getFormattedValue = (value) => {
    return value ? { value, label: value } : null;
  };

  /**
   * Gets the filename from a file path.
   * @function
   * @param file_path {string} - The file path
   * @return {string} - The filename
   */
  getFileName = (file_path) => {
    if (typeof file_path === 'string' && file_path.trim() !== '') {
      return file_path.trim().split('/').pop();
    }
    return '';
  };

  getOriginalSmartwireOptions = (smartwire) => {
    let options = smartwire.original_wire_name.toLowerCase().includes('specialty')
      ? ORIGINAL_SMARTWIRE_OPTIONS.filter((option) => option.value === 'Manual Custom')
      : ORIGINAL_SMARTWIRE_OPTIONS;
    options = smartwire.template === 'Unknown' ? options.filter((option) => option.value !== 'Template') : options;
    return options;
  };

  getIrTypeOptions = (item) => {
    const hasNoOriginalSW0 = item.original_wire_name.includes('specialty') ? !this.state.case_ws.includes(item.original_wire_name) : false;
    const hasNoOriginalManualCustom =
      ['Manual Custom', 'Experimental'].includes(item.original_smartwire) && !this.state.case_ws.includes(item.original_wire_name);

    if (item.original_smartwire === 'No Original Wire' || hasNoOriginalSW0 || hasNoOriginalManualCustom) {
      return IR_TYPE_OPTIONS.filter((option) => option.value !== ORIGINAL_WIRE);
    }
    return IR_TYPE_OPTIONS;
  };

  /**
   * Displays items in a table.
   * @function
   * @param fieldsToDisplay {object} - The fields to be displayed
   * @param edit {boolean} - Whether the fields are editable
   * @param modal {boolean} - Whether it is in modal mode
   * @return {JSX} - JSX for the items table
   */
  displayItems = (fieldsToDisplay, edit = true, modal) => {
    const { original_smartwire, ir_type, wire_type, dxf_file } = fieldsToDisplay;
    const { permission } = this.getStatusConfig();

    let items = this.state.items;
    if (
      this.anyFabricationStatus() &&
      [WS_REDESIGN_STATUS, WS_REDESIGN_FABRICATION_STATUS, WS_CLINICAL_REVIEW_STATUS, WS_REDESIGN_REVIEW_STATUS, WS_CUSTOM_WO_STATUS].includes(
        this.state.ws_automation_status
      )
    ) {
      items = this.state.items.filter(
        (item) => item.status === 'Failure' && (this.state.ws_automation_status === WS_CUSTOM_WO_STATUS ? true : item.course_of_action === 'Redesign')
      );
    }

    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          return (
            <div className="table-items-edit">
              <div className={modal ? '' : 'wrapper'}>
                <table>
                  <thead>
                    <tr className="bold-text">
                      <th className="item-col">Item</th>
                      {original_smartwire.isVisible && <th className="item-col">Original Smartwire</th>}
                      {ir_type.isVisible && <th className="item-col ir-type-col">IR Type</th>}
                      {wire_type.isVisible && <th className="item-col">Wire Type</th>}
                      {dxf_file.isVisible && !modal && <th className="item-col">DXF </th>}
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, idx) => (
                      <React.Fragment key={idx}>
                        <tr>
                          <td className="item-col">{item.ireq_details__ireq_parts__description}</td>
                          {original_smartwire.isVisible && (
                            <td className="item-col">
                              {edit && original_smartwire.isEditable && !this.state.case_ws.includes(item.original_wire_name) ? (
                                <Select
                                  name="original_smartwire_list"
                                  placeholder="Select..."
                                  value={this.getFormattedValue(item?.original_smartwire)}
                                  options={this.getOriginalSmartwireOptions(item)}
                                  onChange={(selectedOption) => this.handleOriginalSmartwireChange(selectedOption, item.original_wire_name)}
                                  classNamePrefix="custom-select"
                                  menuPortalTarget={document.body}
                                />
                              ) : (
                                item?.original_smartwire
                              )}
                            </td>
                          )}
                          {ir_type.isVisible && (
                            <td className="item-col ir-type-col">
                              {edit && ir_type.isEditable ? (
                                <Select
                                  name="ir_type_list"
                                  placeholder="Select..."
                                  value={this.getFormattedValue(item?.ir_type)}
                                  options={this.getIrTypeOptions(item)}
                                  onChange={(selectedOption) => this.handleIRTypeChange(selectedOption, item.original_wire_name)}
                                  classNamePrefix="custom-select"
                                  menuPortalTarget={document.body}
                                />
                              ) : (
                                item?.ir_type
                              )}
                            </td>
                          )}
                          {wire_type.isVisible && (
                            <td className="item-col">
                              {edit && wire_type.isEditable && item?.ir_type !== ORIGINAL_WIRE ? (
                                <Select
                                  name="wire_type_list"
                                  placeholder="Select..."
                                  value={this.getFormattedValue(item?.wire_type)}
                                  options={WIRE_TYPE_OPTIONS}
                                  onChange={(selectedOption) => this.handleWireTypeChange(selectedOption, item.original_wire_name)}
                                  classNamePrefix="custom-select"
                                  menuPortalTarget={document.body}
                                />
                              ) : item?.wire_type ? (
                                item?.wire_type
                              ) : (
                                'N/A'
                              )}
                            </td>
                          )}
                          {dxf_file.isVisible && !modal && (
                            <td className="item-col">
                              {item.original_smartwire === 'Template' && item.ir_type === 'Original Wire' && item.template !== 'Unknown' ? (
                                <>
                                  {this.translateWire(item)} Template {item.template}
                                </>
                              ) : this.getFileName(item?.ireq_manual_process_file__upload_data || item?.ireq_file__upload_data) ? (
                                dxf_file.isVisible ? (
                                  <div className="file-container">
                                    <span
                                      className="download-link"
                                      onClick={() => {
                                        downloadFile(item?.ireq_manual_process_file__upload_data || item?.ireq_file__upload_data);
                                      }}
                                    >
                                      {this.translateWire(item)} {getPartTranslation(item.ireq_details__ireq_parts__part_id, 'label')}
                                      {item.ireq_manual_process_file_id && ' (Manually Uploaded)'}
                                      <i className="fa fa-file-archive-o download-zip-icon" aria-hidden="true" />
                                    </span>
                                    {dxf_file.isDeletable &&
                                    this.state?.assigned_to?.value &&
                                    permission &&
                                    !(item.original_smartwire === 'Template' && item.ir_type === 'Original Wire') &&
                                    !(item.ir_type === 'Original Wire' && item.ireq_file_id) &&
                                    userHasPermission(permission, user_roles_and_permissions.permissions) ? (
                                      <i
                                        className={'fa fa-trash remove-icon'}
                                        aria-hidden="true"
                                        onClick={() => {
                                          if (this.state?.assigned_to?.value) {
                                            this.setState({ remove_file: item });
                                          }
                                        }}
                                      />
                                    ) : null}
                                  </div>
                                ) : null
                              ) : dxf_file.isEditable ? (
                                <button
                                  className="transparent-button"
                                  onClick={() => {
                                    this.onUploadClick('ws_dxf', item);
                                  }}
                                  disabled={
                                    !this.state?.assigned_to?.value || !permission || !userHasPermission(permission, user_roles_and_permissions.permissions)
                                  }
                                >
                                  Upload
                                </button>
                              ) : null}
                            </td>
                          )}
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          );
        }}
      </UserPermissionsContext.Consumer>
    );
  };

  /**
   * Gets the guide details.
   * @function
   * @return {object} - The guide details
   */
  getGuide = () => {
    return this.state?.ws_data?.ws_details?.find(({ group, current_ws }) => group === 'guide' && current_ws);
  };

  /**
   * Displays PDF in a modal.
   * @function
   * @param upload_data {string} - The URL of the PDF
   * @param modified_date {Date} - The date when the PDF was modified
   */
  displayPDF = (upload_data, modified_date, header) => {
    const ireq_id = removeIreqIdInitialNumber(this.props.item_request.ireq_id);

    const modal_pdf = {
      preset: 'pdf_viewer',
      header_text: `${header} - ${ireq_id}`,
      modal_size_class: 'modal-lg modal-lg-pdf',
      modal_body_class: 'modal-pdf',
      pdf_url: upload_data,
      pdf_date: modified_date,
      close_btn_text: 'Close',
      original_filename: this.getFileName(upload_data),
      theme: 'bpp',
    };
    this.setState({
      modal: modal_pdf,
    });
  };

  /**
   * Displays the Adjusted Bracket Measurements Form.
   * @function
   * @return {JSX} - JSX for displaying the Adjusted Bracket Measurements Form
   */
  displayAdjustedBracket = () => {
    if (!this.state.ws_data?.adjusted_bmf_file?.upload_data) return null;
    const fileName = this.state.ws_data?.adjusted_bmf_file?.upload_data;
    const modifiedDate = this.state.ws_data?.adjusted_bmf_file?.modified_date;
    const fileType = 'Adjusted Bracket Measurements Form';
    return (
      <div>
        <span
          className="hyperlink-dark hyperlink-dark-bottom"
          onClick={() => {
            this.displayPDF(fileName, modifiedDate, fileType);
          }}
        >
          Adjusted Bracket Measurements Form <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
        </span>
        <p />
      </div>
    );
  };

  /**
   * Displays the previous Wire Selection Guide.
   * @function
   * @return {JSX} - JSX for displaying the previous Wire Selection Guide
   */
  displayPreviousWireSelectionGuide = () => {
    if (!SHOW_GUIDE_BY_STATUS.includes(this.state.ws_automation_status)) return null;

    const guide = this.getGuide();
    if (!guide) return null;

    const fileName = guide.ireq_manual_process_file__upload_data || guide.ireq_file__upload_data;
    if (!fileName) return null;

    const modifiedDate = guide?.modified_date;
    const fileType = 'Wire Selection Guide';
    const rev = guide.wire_rev.includes('-') ? guide.wire_rev.substr(0, guide.wire_rev.indexOf('-')) : guide.wire_rev;

    return (
      <div>
        <div className="bold-text">Wire Selection Guide</div>
        <span
          className="hyperlink-dark hyperlink-dark-bottom"
          onClick={() => {
            this.displayPDF(fileName, modifiedDate, fileType);
          }}
        >
          Wire Selection Guide <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
        </span>
        <span>{guide['ireq_manual_process_file_id'] && ' (Manually Uploaded)'}</span>
        <span className="bold-text">{`${guide.wire_rev.includes('A') ? '' : ' (Rev#: ' + rev + ')'}`}</span>
        <p />
      </div>
    );
  };

  /**
   * Retrieves the last Ireq Automation Status with status WS Conversion Failed.
   * @returns {object} - last Ireq Automation Status record that failed
   */
  getLastConversionFailed = () => {
    const lastConversionFailed = this.state.ws_data.ws.findIndex((el) => el.status === WS_CONVERSION_FAILED_STATUS);
    return lastConversionFailed === -1 ? null : this.state.ws_data.ws[lastConversionFailed];
  };

  /**
   * Displays conversion status or failure reason.
   * @function
   * @return {JSX} - JSX for displaying conversion status or failure reason
   */
  displayConversion = () => {
    const { fieldsToDisplay, permission, title, noPermissionSubtitle, noPermissionHideContent } = this.getStatusConfig();
    if (this.state.ws_automation_status === WS_CONVERSION_STATUS) {
      return (
        <UserPermissionsContext.Consumer>
          {(user_roles_and_permissions) => {
            return (
              <>
                <div className="center-text">
                  <div className="process-box-intro">
                    <div className="ir process-box-title center-text bold-text">Wire Selection</div>
                    <div className="process-box-content">The wire selection guide for the selected wires is currently being generated...</div>
                    <i className="fa fa-refresh font-size-lg rotate" aria-hidden="true" />
                  </div>
                </div>
                {permission && userHasPermission(permission, user_roles_and_permissions.permissions) && this.displayButtons()}
              </>
            );
          }}
        </UserPermissionsContext.Consumer>
      );
    }

    const conversionFailed = this.state.ws_automation_status === WS_CONVERSION_SUCCEEDED_STATUS ? null : this.getLastConversionFailed();
    const failure_reason = conversionFailed ? conversionFailed.status_comment : '';
    let items = this.state.items;
    if (this.anyFabricationStatus() && [WS_REDESIGN_STATUS, WS_REDESIGN_FABRICATION_STATUS].includes(this.state.ws_automation_status)) {
      items = this.state.items.filter((item) => item.status === 'Failure' && item.course_of_action === 'Redesign');
    }
    let customWires = items.filter((item) => item?.original_smartwire.toLowerCase().includes('custom') || item?.ir_type === 'Redesign');
    if (this.anyFabricationStatus()) {
      customWires = customWires.filter((item) => item.status === 'Failure' && item.course_of_action === 'Redesign');
    }
    const noPermissionItemRequest = this.getNoPermissionComponent(fieldsToDisplay, false, title, noPermissionSubtitle, noPermissionHideContent);
    const guide = this.getGuide();
    const hasGuide = guide && (guide['ireq_manual_process_file_id'] || guide['ireq_file_id']);
    const rev = guide.wire_rev.includes('-') ? guide.wire_rev.substr(0, guide.wire_rev.indexOf('-')) : guide.wire_rev;

    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          return !permission || !userHasPermission(permission, user_roles_and_permissions.permissions) ? (
            noPermissionItemRequest
          ) : (
            <>
              <div className="center-text">
                <div className="process-box-intro">
                  {failure_reason && !hasGuide && (
                    <>
                      <div className="ir process-box-title center-text bold-text">Generation Failed</div>
                      <div className="process-box-content">Wire Selection Guide generation failed for the following reason:</div>
                      <div className="process-box-content bold-text">{failure_reason}</div>
                    </>
                  )}

                  <div className="bold-text center-text">Wire Selection Guide</div>
                  {hasGuide ? (
                    <>
                      <span
                        className="underline-text viewable-text file-span"
                        onClick={() => {
                          this.displayPDF(
                            guide.ireq_manual_process_file__upload_data || guide.ireq_file__upload_data,
                            guide?.modified_date,
                            'Wire Selection Guide'
                          );
                        }}
                      >
                        Wire Selection Guide <i className="fa fa-file-pdf-o" aria-hidden="true" />
                      </span>
                      <span>{guide['ireq_manual_process_file_id'] && ' (Manually Uploaded)'} </span>
                      <span className="bold-text">{`${rev.includes('A') ? ' ' : ' (Rev#: ' + rev + ') '}`}</span>
                      <i
                        className={'fa fa-trash remove-icon'}
                        aria-hidden="true"
                        onClick={() => {
                          this.setState({ remove_file: guide });
                        }}
                      />
                    </>
                  ) : (
                    <button
                      className="transparent-button centered-button"
                      onClick={() => {
                        this.onUploadClick('ws_guide', this.getGuide());
                      }}
                    >
                      Upload
                    </button>
                  )}
                  {customWires.length > 0 && (
                    <>
                      <div className="bold-text mt-10">Custom Wire</div>
                      {customWires.map((item, index) => {
                        const fileName = this.getFileName(item?.ireq_manual_process_file__upload_data || item?.ireq_file__upload_data);
                        return fileName ? (
                          <div key={index}>
                            <span
                              className="download-link"
                              onClick={() => {
                                downloadFile(item?.ireq_manual_process_file__upload_data || item?.ireq_file__upload_data);
                              }}
                            >
                              {this.translateWire(item)} {getPartTranslation(item.ireq_details__ireq_parts__part_id, 'label')}
                              {[WS_MANUAL_CONVERSION_STATUS, WS_CONVERSION_FAILED_STATUS, WS_CONVERSION_SUCCEEDED_STATUS].includes(
                                this.state.ws_automation_status
                              ) && item.ireq_manual_process_file_id
                                ? ' (Manually Uploaded)'
                                : ''}
                              <i className="fa fa-file-archive-o download-zip-icon" aria-hidden="true" />
                            </span>
                          </div>
                        ) : null;
                      })}
                    </>
                  )}
                </div>
              </div>
              {this.displayButtons()}
            </>
          );
        }}
      </UserPermissionsContext.Consumer>
    );
  };

  /**
   * Constructs and returns the field configuration based on the current automation status.
   * @function
   * @return {Object} - Contains fields to display, required permissions, title, subtitle, and no_permission_subtitle based on the current automation status
   */
  getStatusConfig = () => {
    const fieldsToDisplay = _.cloneDeep(FIELDS_TO_DISPLAY);
    let permission, title, subtitle, noPermissionSubtitle, noPermissionHideContent;
    const current_rev = this.state?.ws_data?.ws_details?.find((item) => item.current_rev === true)['wire_rev'];
    const rework_num = current_rev ? current_rev.toLowerCase().charCodeAt(0) - 'a'.charCodeAt(0) : 0;

    switch (this.state.ws_automation_status) {
      case WS_SELECTION_STATUS:
        fieldsToDisplay.original_smartwire.isEditable = true;
        fieldsToDisplay.ir_type.isEditable = true;
        permission = 'IREQ_WIRE_SELECTION_GENERATE';
        title = 'Wire Selection';
        subtitle = 'Select the original wire type and item request type';
        noPermissionSubtitle = 'Wire selection in progress...';
        noPermissionHideContent = true;
        break;
      case WS_RECOMMENDATION_STATUS:
        fieldsToDisplay.original_smartwire.isEditable = false;
        fieldsToDisplay.ir_type.isEditable = true;
        fieldsToDisplay.wire_type.isVisible = true;
        fieldsToDisplay.wire_type.isEditable = true;
        permission = 'IREQ_DESIGN_RECOMMENDATION';
        title = 'Wire Design Recommendation';
        subtitle = 'Select the wire type and enter wire design recommendation notes as needed';
        noPermissionSubtitle = 'Wire design recommendation in progress...';
        noPermissionHideContent = false;
        break;
      case WS_CLINICAL_REVIEW_STATUS:
        fieldsToDisplay.original_smartwire.isEditable = false;
        fieldsToDisplay.ir_type.isEditable = !this.anyFabricationStatus();
        fieldsToDisplay.wire_type.isVisible = true;
        fieldsToDisplay.wire_type.isEditable = true;
        permission = 'IREQ_CLINICAL_REVIEW';
        title = 'Clinical Review';
        subtitle = (
          <>
            Please review the information below. <b>Approve</b> to begin wire redesign.
          </>
        );
        noPermissionSubtitle = 'Clinical review in progress...';
        noPermissionHideContent = false;
        break;
      case WS_REDESIGN_STATUS:
        fieldsToDisplay.wire_type.isVisible = true;
        fieldsToDisplay.dxf_file.isVisible = true;
        fieldsToDisplay.dxf_file.isEditable = true;
        fieldsToDisplay.dxf_file.isDeletable = true;
        permission = 'IREQ_WIRE_REDESIGN';
        const rework = rework_num > 0 ? ` (Rework ${rework_num})` : '';
        title = 'Wire Redesign and DXF Upload' + rework;
        subtitle = (
          <>
            Upload the redesigned wire and click <b>Request Review</b>
          </>
        );
        noPermissionSubtitle = 'Wire Redesign and DXF Upload in progress...';
        noPermissionHideContent = false;
        break;
      case WS_REDESIGN_FABRICATION_STATUS:
        fieldsToDisplay.wire_type.isVisible = true;
        fieldsToDisplay.wire_type.isEditable = true;
        permission = 'IREQ_DESIGN_RECOMMENDATION';
        title = 'Wire Design Recommendation';
        subtitle = 'Select the wire type and enter wire design recommendation notes as needed';
        noPermissionSubtitle = 'Wire design recommendation in progress...';
        noPermissionHideContent = false;
        break;
      case WS_REDESIGN_REVIEW_STATUS:
        fieldsToDisplay.wire_type.isVisible = true;
        fieldsToDisplay.dxf_file.isVisible = true;
        fieldsToDisplay.dxf_file.isEditable = false;
        fieldsToDisplay.dxf_file.isDeletable = false;
        permission = 'IREQ_WIRE_REDESIGN_REVIEW';
        title = 'Redesign Review';
        subtitle = 'Review Redesign and Wire Selection Guide';
        noPermissionSubtitle = 'Redesign review in progress...';
        noPermissionHideContent = false;
        break;
      case WS_CONVERSION_STATUS:
        permission = 'IREQ_WIRE_SELECTION_BYPASS';
        noPermissionHideContent = false;
        break;
      case WS_CONVERSION_FAILED_STATUS:
      case WS_CONVERSION_SUCCEEDED_STATUS:
        permission = 'IREQ_WIRE_REDESIGN_REVIEW';
        title = 'Generation Failed';
        noPermissionSubtitle = 'Redesign review in progress...';
        noPermissionHideContent = false;
        break;
      case WS_SELECTION_STATUS:
        fieldsToDisplay.original_smartwire.isEditable = true;
        fieldsToDisplay.ir_type.isEditable = true;
        permission = 'IREQ_APPROVE';
        title = 'Wire Selection';
        subtitle = 'Select the original wire type and item request type';
        noPermissionSubtitle = 'Wire selection in progress...';
        noPermissionHideContent = true;
        break;
      case WS_CUSTOM_WO_STATUS:
        fieldsToDisplay.dxf_file.isVisible = true;
        fieldsToDisplay.dxf_file.isEditable = false;
        permission = 'IREQ_WIRE_SELECTION_CUSTOM';
        title = 'Custom Wire WO Creation';
        noPermissionSubtitle = 'Custom Wire WO Creation in progress...';
        noPermissionHideContent = true;
        break;
      default:
        if (
          [
            WS_WIRE_FABRICATION_STATUS,
            WS_COMPLETE_STATUS,
            WS_COMPLETE_FABRICATION_STATUS,
            WS_WIRE_FABRICATION_RESOLVED_STATUS,
            WS_WIRE_FABRICATION_REINITIATED_STATUS,
          ].includes(this.state.ws_automation_status)
        ) {
          fieldsToDisplay.wire_type.isVisible = true;
          fieldsToDisplay.dxf_file.isVisible = true;
          fieldsToDisplay.dxf_file.isEditable = false;
          fieldsToDisplay.dxf_file.isDeletable = false;
        }
        break;
    }
    return { fieldsToDisplay, permission, title, subtitle, noPermissionSubtitle, noPermissionHideContent };
  };

  /**
   * Returns the template for an item request based on given parameters.
   * @function
   * @param {Object} fieldsToDisplay - The fields that should be displayed in the template.
   * @param {boolean} modal - Flag to determine if the modal view should be used.
   * @param {string} title - Title to be displayed on the template.
   * @param {string} subtitle - Subtitle to be displayed on the template.
   * @return {JSX} - JSX for displaying the item request template.
   */
  getItemRequestComponent = (fieldsToDisplay, modal, title, subtitle) => {
    if (modal) {
      return (
        <>
          {this.displayItems(fieldsToDisplay, false, modal)}
          {this.displayNotes(false)}
        </>
      );
    }
    return (
      <>
        <div className="item-request-text">
          {ASSIGNED_TO_STATUSES.includes(this.state.ws_automation_status) ? this.displayAssignedTo(title) : <div className="bold-text">{title}</div>}
          {this.checkAssignment() && subtitle}
        </div>
        {this.checkAssignment() && (
          <>
            {this.displayCustomWOAndFabricationBtn()}
            {this.displayAdjustedBracket()}
            {this.displayPreviousWireSelectionGuide()}
            {this.displayItems(fieldsToDisplay, true, modal)}
            {this.displayNotes()}
            {this.displayRevisionNotes(modal)}
            {WS_CUSTOM_WO_STATUS === this.state.ws_automation_status && this.displayCustomWO()}
            {this.displayButtons()}
          </>
        )}
      </>
    );
  };

  /**
   * Returns the template for a no permission view based on given parameters.
   * @function
   * @param {Object} fieldsToDisplay - The fields that should be displayed in the template.
   * @param {boolean} modal - Flag to determine if the modal view should be used.
   * @param {string} title - Title to be displayed on the template.
   * @param {string} no_permission_subtitle - Subtitle to be displayed when user has no permissions.
   * @return {JSX} - JSX for displaying the no permission template.
   */
  getNoPermissionComponent = (fieldsToDisplay, modal, title, no_permission_subtitle, noPermissionHideContent) => {
    return (
      <div className="bpp-cozy-text-headlines cozy-paragraph">
        <div className="item-request-text">
          <div className="bold-text">{title}</div>
          <p />
          {no_permission_subtitle}
        </div>
        {!noPermissionHideContent && (
          <>
            {this.displayCustomWOAndFabricationBtn()}
            {this.displayAdjustedBracket()}
            {this.displayPreviousWireSelectionGuide()}
            {this.displayItems(fieldsToDisplay, false, modal)}
            {this.displayNotes(false)}
            {this.displayRevisionNotes(modal)}
          </>
        )}
      </div>
    );
  };

  /**
   * Displays items based on current automation status.
   * @function
   * @param {boolean} modal - Flag to determine if the modal view should be used.
   * @return {JSX} - JSX for displaying the items based on current automation status.
   */
  displayItemsByStatus = (modal = false) => {
    const { fieldsToDisplay, permission, title, subtitle, noPermissionSubtitle, noPermissionHideContent } = this.getStatusConfig();

    const itemRequest = this.getItemRequestComponent(fieldsToDisplay, modal, title, subtitle);
    const noPermissionItemRequest = this.getNoPermissionComponent(fieldsToDisplay, modal, title, noPermissionSubtitle, noPermissionHideContent);
    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          return !permission || userHasPermission(permission, user_roles_and_permissions.permissions) ? itemRequest : noPermissionItemRequest;
        }}
      </UserPermissionsContext.Consumer>
    );
  };

  /**
   * Returns a button for a specified action.
   * @function
   * @param {string} action_modal - Action to be performed when the button is clicked.
   * @param {string} label - Text to be displayed on the button.
   * @param {boolean} enabled - Flag to determine if the button is enabled.
   * @return {JSX} - JSX for the action button.
   */
  getActionButton(action_modal, label, enabled = true) {
    return (
      <button type="button" className="btn btn-light" onClick={() => this.setState({ action_modal })} disabled={!enabled}>
        {label}
      </button>
    );
  }

  checkAssignment = () => {
    /**
     * Checks whether there is a user assigned to relevant IR Wire Selection steps
     */
    const assigned_steps = ['WS Redesign Review', 'WS Recommendation', 'WS Redesign', 'WS Redesign Fabrication', 'WS Clinical Review'];
    if (assigned_steps.includes(this.state.ws_automation_status) && !this.state?.assigned_to?.value) {
      return false;
    }
    return true;
  };

  /**
   * Returns buttons for available actions based on current automation status.
   * @function
   * @return {JSX} - JSX for displaying action buttons based on current automation status.
   */
  getActionButtons() {
    const {
      ws_automation_status,
      all_original_smartwire_selected,
      all_ir_type_selected,
      all_wire_type_selected,
      all_original_wire,
      any_custom,
      any_pusher,
      all_files_uploaded,
    } = this.state;
    let action_buttons = null;
    let action_modal;
    if (ws_automation_status === WS_SELECTION_STATUS) {
      const enabled = all_original_smartwire_selected && all_ir_type_selected;
      action_buttons = (
        <>
          {all_original_wire
            ? this.getActionButton('approve_original_wire', 'Approve', enabled)
            : this.getActionButton('request_recommendation', 'Request Review', enabled)}
        </>
      );
    }
    if (ws_automation_status === WS_RECOMMENDATION_STATUS) {
      const enabled = all_original_smartwire_selected && all_ir_type_selected && all_wire_type_selected;
      action_buttons = (
        <>
          {any_pusher && this.getActionButton('request_clinical_review', 'Request Review', enabled)}
          {!any_pusher && this.getActionButton(all_original_wire ? 'approve_original_wire' : 'approve_recommendation', 'Approve', enabled)}
        </>
      );
    }
    if (ws_automation_status === WS_CLINICAL_REVIEW_STATUS) {
      const enabled = all_original_smartwire_selected && all_ir_type_selected && all_wire_type_selected;
      action_buttons = this.getActionButton(all_original_wire ? 'approve_original_wire' : 'approve_clinical_review', 'Approve', enabled);
    }
    if (ws_automation_status === WS_REDESIGN_STATUS) {
      action_buttons = this.getActionButton('request_redesign_review', 'Request Review', !!(all_files_uploaded && this.state?.assigned_to?.value));
    }
    if (ws_automation_status === WS_REDESIGN_FABRICATION_STATUS) {
      const enabled = all_original_smartwire_selected && all_ir_type_selected && all_wire_type_selected;
      action_buttons = (
        <>
          {any_pusher && this.getActionButton('request_clinical_review', 'Request Review', enabled)}
          {!any_pusher && this.getActionButton('approve_recommendation', 'Approve', enabled)}
        </>
      );
    }
    if (ws_automation_status === WS_CUSTOM_WO_STATUS) {
      action_modal = this.anyFabricationStatus() ? 'complete_custom_wo_fabrication' : 'complete_custom_wo';
      action_buttons = this.getActionButton(action_modal, 'Complete', !!this.state.custom_wo_file);
    }
    if (ws_automation_status === WS_REDESIGN_REVIEW_STATUS) {
      action_modal = any_custom ? 'approve_redesign_review_custom' : 'approve_redesign_review';
      action_buttons = (
        <>
          {this.getActionButton(action_modal, 'Approve')}
          {this.getActionButton('reject_redesign_review', 'Reject')}
        </>
      );
    }
    if (ws_automation_status === WS_CONVERSION_STATUS) {
      action_buttons = (
        <>
          {this.getActionButton('convert_success', 'Mark as Success')}
          {this.getActionButton('convert_failed', 'Mark as Failed')}
        </>
      );
    }
    if ([WS_CONVERSION_FAILED_STATUS, WS_CONVERSION_SUCCEEDED_STATUS].includes(ws_automation_status)) {
      const guide = this.getGuide();
      const hasGuide = guide && (guide['ireq_manual_process_file_id'] || guide['ireq_file_id']);
      let action = null;
      let returnAction = null;
      let returnVerbiage = 'Return to Wire Selection';
      const stateBeforeConversion = this.getStateBeforeConversion();

      if (stateBeforeConversion === WS_SELECTION_STATUS) {
        action = this.state.any_custom ? 'original_wire_guide_upload_custom' : 'original_wire_guide_upload';
        returnAction = 'return_to_wire_selection';
      } else if (stateBeforeConversion === WS_RECOMMENDATION_STATUS) {
        if (!all_original_wire) {
          action = 'redesign_guide_upload';
        } else {
          action = this.state.any_custom ? 'original_wire_guide_upload_custom' : 'original_wire_guide_upload';
        }
        returnAction = 'return_to_wire_recommendation';
        returnVerbiage = 'Return to Wire Recommendation';
      } else if (stateBeforeConversion === WS_CLINICAL_REVIEW_STATUS) {
        action = 'original_wire_guide_upload';
        returnAction = 'return_to_clinical_review';
        returnVerbiage = 'Return to Clinical Review';
      } else if (stateBeforeConversion === WS_REDESIGN_STATUS) {
        action = 'redesign_guide_upload';
        returnAction = 'return_to_wire_redesign';
        returnVerbiage = 'Return to Wire Redesign';
      } else if (stateBeforeConversion === WS_REDESIGN_FABRICATION_STATUS) {
        action = this.state.any_custom ? 'redesign_guide_upload_custom' : 'redesign_guide_upload_complete';
        returnAction = 'return_to_wire_redesign_fabrication';
        returnVerbiage = 'Return to Wire Redesign';
      }

      action_buttons = (
        <>
          {
            <div className="return-link">
              {/* eslint-disable-next-line */}
              <a onClick={() => this.setState({ action_modal: returnAction })}>
                <i className="fa fa-long-arrow-left" aria-hidden="true" /> {returnVerbiage}
              </a>
            </div>
          }
          {hasGuide && this.getActionButton(action, 'Proceed')}
        </>
      );
    }
    return action_buttons;
  }

  /**
   * Displays action buttons based on current automation status.
   * @function
   * @return {JSX} - JSX for displaying the action buttons based on current automation status.
   */
  displayButtons() {
    const action_buttons = this.getActionButtons();
    if (action_buttons) {
      return (
        <div>
          <div className="bpp-10-padding" />
          <div className="text-center">{action_buttons}</div>
        </div>
      );
    }

    return null;
  }

  /**
   * Displays notes in a disabled state.
   * @function
   * @return {JSX} - JSX for displaying notes in a disabled state.
   */
  displayDisabledNotes = () => {
    return (
      this.state.recommendation_notes && (
        <div className={'notes-disabled-container text-left'}>
          <span className="bold-text">
            Notes
            <br />
          </span>
          <div>{this.state.recommendation_notes}</div>
        </div>
      )
    );
  };

  /**
   * Displays notes based on current automation status.
   * @function
   * @param {boolean} edit - Flag to determine if the notes are editable.
   * @return {JSX} - JSX for displaying notes based on current automation status.
   */
  displayNotes(edit = true) {
    const showStatusObject = SHOW_NOTES_BY_STATUS[this.state.ws_automation_status];
    if (showStatusObject) {
      return edit && showStatusObject.isEditable ? (
        <div className="notes-edit-container">
          <span className="bold-text">
            Notes
            <br />
          </span>
          <textarea
            name="review_wire_selection_notes"
            placeholder="Enter notes here..."
            maxLength={5000}
            value={this.state.recommendation_notes}
            onChange={this.handleRecommendationNotesChange}
            className="wire-selection-notes-edit"
          ></textarea>
        </div>
      ) : (
        this.displayDisabledNotes()
      );
    }
    return null;
  }

  /**
   * Displays the revision notes based on the current automation status.
   * @function
   * @param {boolean} modal - Flag to determine if the modal view should be used.
   * @return {JSX} - JSX for displaying the revision notes.
   */
  displayRevisionNotes(modal) {
    const statusObject = SHOW_REVISION_NOTES_BY_STATUSES[this.state.ws_automation_status];
    if (statusObject?.only_in_modal && !modal) return null;
    if (statusObject) {
      if (statusObject.isEditable) {
        return (
          <div className="notes-edit-container">
            <textarea
              name="review_wire_selection_notes"
              placeholder="Specify reason for rejection"
              value={this.state.revision_notes}
              onChange={this.handleRevisionNotesChange}
              className="wire-selection-notes-edit"
            ></textarea>
          </div>
        );
      } else {
        if (!this.state.revision_notes) return null;
        return (
          <div className={'notes-disabled-container text-left'}>
            <span className="bold-text">
              Revision Notes
              <br />
            </span>
            <div className>{this.state.revision_notes}</div>
          </div>
        );
      }
    }
    return null;
  }

  /**
   * Updates the automation status with a specified action and refreshes the workflow.
   * @function
   * @param {string} action - The action to be performed.
   */
  fetchUpdateStatusAction(action) {
    setTokenHeader();
    this.setState({ modal_in_progress: true });
    const that = this;
    const ws_workflow_data = {
      items: this.state.items,
      revision_notes: this.state.revision_notes,
    };
    Axios.post(`/apiv3/ireqautomationstatus/${this.props.item_request.ireq_id}/ws/${action}`, { ws_workflow_data })
      .then(function (res) {
        that.refreshWSWorkflow();
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);

        if (err && err.response && err.response.status === 409) {
          that.setState({
            action_modal: '',
            modal_in_progress: false,
          });
        }
      });
  }

  /**
   * Confirms the action to be taken when the modal is confirmed.
   * @function
   */
  onModalConfirm() {
    this.fetchUpdateStatusAction(this.state.action_modal);
  }

  /**
   * Retrieves the modals for the given ired_id.
   *
   * @param {string} ired_id - The ired_id.
   * @returns {object} Returns an object containing the modals.
   */
  getModals = (ired_id) => {
    const fieldsToDisplay = _.cloneDeep(FIELDS_TO_DISPLAY);
    if (
      [
        WS_RECOMMENDATION_STATUS,
        WS_CLINICAL_REVIEW_STATUS,
        WS_REDESIGN_STATUS,
        WS_REDESIGN_FABRICATION_STATUS,
        WS_REDESIGN_REVIEW_STATUS,
        WS_CONVERSION_FAILED_STATUS,
      ].includes(this.state.ws_automation_status)
    ) {
      fieldsToDisplay.wire_type.isVisible = true;
    }

    const modals = {
      approve_original_wire: {
        preset: 'decision-wire-selection',
        header_text: `Approve Wire Selection - ${ired_id}`,
        message_text: (
          <>
            <p>Are you sure you want to approve the following wire selection?</p>
            {this.displayItemsByStatus(true)}
          </>
        ),
        confirm_btn_text: 'Approve',
        close_btn_text: 'Cancel',
        in_progress_text: 'Approving...',
      },
      request_recommendation: {
        preset: 'decision-wire-selection',
        header_text: `Request Review - ${ired_id}`,
        message_text: (
          <>
            <p>Are you sure you would like to send this item request for review?</p>
            {this.displayItemsByStatus(true)}
          </>
        ),
        confirm_btn_text: 'Request Review',
        close_btn_text: 'Cancel',
        in_progress_text: 'Requesting...',
      },
      request_clinical_review: {
        preset: 'decision-wire-selection',
        header_text: `Request Review - ${ired_id}`,
        message_text: (
          <>
            <p>Are you sure you would like to send this item request for review?</p>
            {this.displayItemsByStatus(true)}
          </>
        ),
        confirm_btn_text: 'Request Review',
        close_btn_text: 'Cancel',
        in_progress_text: 'Requesting...',
      },
      approve_recommendation: {
        preset: 'decision-wire-selection',
        header_text: `Approve Wire Selection - ${ired_id}`,
        message_text: (
          <>
            <p>Are you sure you want to appprove the following wire selection?</p>
            {this.displayItemsByStatus(true)}
          </>
        ),
        confirm_btn_text: 'Approve',
        close_btn_text: 'Cancel',
        in_progress_text: 'Requesting...',
      },
      approve_clinical_review: {
        preset: 'decision-wire-selection',
        header_text: `Approve Wire Selection - ${ired_id}`,
        message_text: (
          <>
            <p>Are you sure you want to appprove the following wire selection?</p>
            {this.displayItemsByStatus(true)}
          </>
        ),
        confirm_btn_text: 'Approve',
        close_btn_text: 'Cancel',
        in_progress_text: 'Approving...',
      },
      request_redesign_review: {
        preset: 'decision-wire-selection',
        header_text: `Request Review - ${ired_id}`,
        message_text: (
          <>
            <p>Are you sure you would like to send this item request for review?</p>
            {this.displayItemsByStatus(true)}
          </>
        ),
        confirm_btn_text: 'Request Review',
        close_btn_text: 'Cancel',
        in_progress_text: 'Requesting...',
      },
      approve_redesign_review: {
        preset: 'decision-wire-selection',
        header_text: `Approve Wire Selection - ${ired_id}`,
        message_text: (
          <>
            <p>Are you sure you want to appprove the following wire selection?</p>
            {this.displayItemsByStatus(true)}
          </>
        ),
        confirm_btn_text: 'Approve',
        close_btn_text: 'Cancel',
        in_progress_text: 'Approving...',
      },
      reject_redesign_review: {
        preset: 'decision-wire-selection',
        header_text: `Reject Wire Selection - ${ired_id}`,
        message_text: (
          <>
            <p>Are you sure you would like to send this item request back for revision?</p>
          </>
        ),
        modal_body: this.displayRevisionNotes(true),
        confirm_btn_text: 'Reject',
        close_btn_text: 'Cancel',
        in_progress_text: 'Rejecting...',
        confirm_btn_disabled: !this.state.revision_notes || !this.state.revision_notes.trim(),
      },
      complete_custom_wo: {
        preset: 'decision-wire-selection',
        header_text: `Complete Custom Wire WO - ${ired_id}`,
        message_text: (
          <>
            <p>Are you sure you would like to complete Custom Wire WO?</p>
          </>
        ),
        confirm_btn_text: 'Complete',
        in_progress_text: 'Completing...',
      },
      initiate_fabrication: {
        preset: 'decision-wire-selection',
        header_text: `Initiate Wire Fabrication Process - ${ired_id}`,
        message_text: (
          <>
            <p>Are you sure you would like to initiate wire fabrication process?</p>
          </>
        ),
        confirm_btn_text: 'Initiate',
        close_btn_text: 'Cancel',
        in_progress_text: 'Initiating...',
      },
      convert_success: {
        preset: 'decision-wire-selection',
        header_text: `Mark as Success - ${ired_id}`,
        message_text: (
          <>
            <p>Are you sure you would like to mark as success?</p>
          </>
        ),
        confirm_btn_text: 'Mark as Success',
        close_btn_text: 'Cancel',
        in_progress_text: 'Requesting...',
      },
      convert_failed: {
        preset: 'decision-wire-selection',
        header_text: `Mark as Failed - ${ired_id}`,
        message_text: (
          <>
            <p>Are you sure you would like to mark as failed?</p>
          </>
        ),
        confirm_btn_text: 'Mark as Failed',
        close_btn_text: 'Cancel',
        in_progress_text: 'Requesting...',
      },
      return_to_wire_selection: {
        preset: 'decision-wire-selection',
        header_text: `Return to Wire Selection - ${ired_id}`,
        message_text: (
          <>
            <p>Are you sure you want to return to Wire Selection?</p>
          </>
        ),
        confirm_btn_text: 'Return',
        close_btn_text: 'Cancel',
        in_progress_text: 'Returning...',
      },
      return_to_wire_redesign: {
        preset: 'decision-wire-selection',
        header_text: `Return to Wire Redesign - ${ired_id}`,
        message_text: (
          <>
            <p>Are you sure you want to return to Wire Redesign?</p>
          </>
        ),
        confirm_btn_text: 'Return',
        close_btn_text: 'Cancel',
        in_progress_text: 'Returning...',
      },
      return_to_wire_recommendation: {
        preset: 'decision-wire-selection',
        header_text: `Return to Wire Recommendation - ${ired_id}`,
        message_text: (
          <>
            <p>Are you sure you want to return to Wire Recommendation?</p>
          </>
        ),
        confirm_btn_text: 'Return',
        close_btn_text: 'Cancel',
        in_progress_text: 'Returning...',
      },
      return_to_clinical_review: {
        preset: 'decision-wire-selection',
        header_text: `Return to Clinical Review - ${ired_id}`,
        message_text: (
          <>
            <p>Are you sure you want to return to Clinical Review?</p>
          </>
        ),
        confirm_btn_text: 'Return',
        close_btn_text: 'Cancel',
        in_progress_text: 'Returning...',
      },
      original_wire_guide_upload: {
        preset: 'decision-wire-selection',
        header_text: `Wire Selection Guide - ${ired_id}`,
        message_text: (
          <>
            <p>Are you sure you want to mark the Wire Selection Guide process as complete?</p>
          </>
        ),
        confirm_btn_text: 'Proceed',
        close_btn_text: 'Cancel',
        in_progress_text: 'Requesting...',
      },
    };

    modals.approve_redesign_review_custom = { ...modals.approve_redesign_review };
    modals.complete_custom_wo_fabrication = { ...modals.complete_custom_wo };
    modals.original_wire_guide_upload_custom = { ...modals.original_wire_guide_upload };
    modals.redesign_guide_upload = { ...modals.original_wire_guide_upload };
    modals.redesign_guide_upload_custom = { ...modals.original_wire_guide_upload };
    modals.redesign_guide_upload_complete = { ...modals.original_wire_guide_upload };
    modals.return_to_wire_redesign_fabrication = { ...modals.return_to_wire_redesign };

    return modals;
  };

  /**
   * Renders a modal based on current action_modal state.
   * @returns {component|null} - A Modal component or null if there's no matching action_modal
   */
  displayModalAction = () => {
    const { action_modal } = this.state;
    const ired_id = removeIreqIdInitialNumber(this.props.item_request.ireq_id);
    const fieldsToDisplay = _.cloneDeep(FIELDS_TO_DISPLAY);
    if (
      [
        WS_RECOMMENDATION_STATUS,
        WS_CLINICAL_REVIEW_STATUS,
        WS_REDESIGN_STATUS,
        WS_REDESIGN_FABRICATION_STATUS,
        WS_REDESIGN_REVIEW_STATUS,
        WS_MANUAL_CONVERSION_STATUS,
        WS_CONVERSION_FAILED_STATUS,
        WS_CONVERSION_SUCCEEDED_STATUS,
      ].includes(this.state.ws_automation_status)
    ) {
      fieldsToDisplay.wire_type.isVisible = true;
    }

    const modals = this.getModals(ired_id);
    if (action_modal in modals) {
      return (
        <Modal
          onCloseButtonClick={() => {
            this.setState({ action_modal: '' });
            if (action_modal === 'reject_redesign_review') this.setState({ revision_notes: '' });
          }}
          theme="bpp"
          {...modals[action_modal]}
          in_progress={this.state.modal_in_progress}
          onConfirmButtonClick={this.onModalConfirm}
        />
      );
    }

    return null;
  };

  /**
   * Constructs the location string for a given label.
   * @returns {string} - The constructed location string
   */
  constructLocation = (label) => {
    let location = 'ws_dxf_' + label.toLowerCase().replace('smartwire', '').replace(' ', '_').replace('de', '').replaceAll(/\s/g, '');
    return location;
  };

  /**
   * Resets all file name error states to false.
   */
  resetFileNameError = () => {
    this.props.setDxfFileNameError(false, 'case_id');
    this.props.setDxfFileNameError(false, 'format');
    this.props.setWsGuideFileNameError(false);
    this.props.setCustomWoFileNameError(false);
  };

  /**
   * Sends a request to delete the IR file specified in the current state.
   */
  wsRemoveIrFile = () => {
    const irFile = this.state.remove_file.file;
    const that = this;

    Axios.delete(`/apiv3/irfile/${irFile.id}`)
      .then(function (res) {
        that.refreshWSWorkflow();
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  /**
   * Based on the file type in the state, either calls wsRemoveIrFile or sends a request to remove a specified file.
   */
  wsRemoveFile = () => {
    if (this.state.remove_file?.file_type === 'ws_custom_wo') {
      return this.wsRemoveIrFile();
    }
    const item = this.state.remove_file;
    const data = [
      {
        ireq_wire_selection_id: item.id,
        ireq_file_id: item.ireq_manual_process_file_id,
        manual_process: true,
      },
    ];
    const ireq_id = this.props.item_request.ireq_id;
    const that = this;

    Axios.put(`/apiv3/ireqautomationstatus/${ireq_id}/ws/remove`, data)
      .then(function (res) {
        if (res && res.data && res.data.ws && res.data.ws.length > 0) {
          that.refreshWSWorkflow();
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  /**
   * Retrieves the previous state before a WS Conversion occurred.
   * @returns {string} - The status of the state before the conversion
   */
  getStateBeforeConversion = () => {
    const lastConversion = this.state.ws_data.ws.findIndex((el) => el.status === 'WS Conversion');
    return this.state.ws_data.ws[lastConversion + 1]?.status;
  };

  /**
   * Sends a request to upload a file with specified parameters.
   * @param {array} data - The file data
   * @param {number} ws_id - The wire selection ID
   * @param {string} type - The type of the file
   * @param {string} action - The type of action (upload or custom_wo_upload)
   */
  fetchUpload = (data, ws_id, type, action) => {
    const mime_types = {
      ws_custom_wo: 'application/pdf',
      ws_dxf: 'application/dxf',
      ws_guide: 'application/pdf',
    };
    data[0]['ireq_wire_selection_id'] = ws_id;
    data[0]['mime_type'] = mime_types[type];
    data[0]['manual_process'] = true;
    data[0]['file_type'] = type;
    const ireq_id = this.props.item_request.ireq_id;
    const that = this;

    Axios.put(`/apiv3/ireqautomationstatus/${ireq_id}/ws/${action}`, data)
      .then(function (res) {
        if (res && res.data && res.data.ws && res.data.ws.length > 0) {
          that.refreshWSWorkflow();
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
        that.setState({ upload_modal: null });
      });
  };

  /**
   * Sets up and initiates the upload modal.
   * @param {string} type - The type of the file
   * @param {object} item - Optional. The selected item
   */
  onUploadClick = (type, item = null) => {
    let action = type === 'ws_custom_wo' ? 'custom_wo_upload' : 'upload';
    const ireq_id = this.props.item_request.ireq_id;
    const label =
      type === 'ws_dxf' ? getPartTranslation(item.ireq_details__ireq_parts__part_id, 'label') : type === 'ws_guide' ? 'Wire Selection Guide' : 'Custom WO';
    const wire_name = ['ws_dxf', 'ws_guide'].includes(type) ? item?.original_wire_name : '';
    const wire_rev = type === 'ws_dxf' ? item.wire_rev : this.getGuide()?.wire_rev;

    this.resetFileNameError();
    const config = {
      header_text: `${label} - ${removeIreqIdInitialNumber(ireq_id)}`,
      ireq_id: ireq_id,
      type: type,
      onUpload: (data) => {
        this.fetchUpload(data, item?.id, type, action);
      },
      location: type === 'ws_dxf' ? this.constructLocation(label) : type === 'ws_custom_wo' ? 'ws_custom_wo' : 'ws_guide_mp',
      ws_id: item?.id,
      wire_name: wire_name,
      wsg_rev: wire_rev,
      replace: false,
      ir_wo_history: this.state.ir_wo_history,
    };

    this.setState({
      upload_modal: config,
    });
  };

  /**
   * Displays a modal for removing a file.
   * @returns {component} - A Modal component for removing a file
   */
  displayModalRemove = () => {
    return (
      <Modal
        onCloseButtonClick={() => this.setState({ remove_file: '' })}
        theme="bpp"
        preset="decision-wire-selection"
        header_text="Remove DXF File"
        message_text="Are you sure you would like to remove this file?"
        confirm_btn_text="Remove"
        close_btn_text="Cancel"
        in_progress_text="Removing..."
        in_progress={this.state.modal_in_progress}
        onConfirmButtonClick={this.wsRemoveFile}
      />
    );
  };

  /**
   * Displays an upload modal.
   * @returns {component} - An Upload component
   */
  displayUploadModal = () => {
    return (
      <Upload
        onClose={() => {
          this.setState({ upload_modal: null });
        }}
        {...this.state.upload_modal}
      />
    );
  };

  /**
   * Displays a general modal.
   * @returns {component} - A Modal component
   */
  displayModal = () => {
    return (
      <Modal
        onCloseButtonClick={() => {
          this.setState({ modal: null });
        }}
        {...this.state.modal}
      />
    );
  };

  /**
   * Renders the Custom Work Order (WO) and Fabrication buttons.
   * @returns {component} - A button component or a container with multiple buttons
   */
  displayCustomWOAndFabricationBtn = () => {
    const isInitiateButtonAvailable =
      !this.props.item_request.shipping_approved_ind && [WS_COMPLETE_STATUS, WS_WIRE_FABRICATION_RESOLVED_STATUS].includes(this.state.ws_automation_status);

    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          return (
            <div className="custom-wo-and-fabrication-container">
              {this.state.ws_automation_status !== WS_CUSTOM_WO_STATUS && this.displayCustomWO()}
              {userHasPermission('IREQ_WIRE_FABRICATION_REVIEW', user_roles_and_permissions.permissions) &&
                isInitiateButtonAvailable &&
                this.displayInitiateFabricationBtn()}
            </div>
          );
        }}
      </UserPermissionsContext.Consumer>
    );
  };

  /**
   * Renders the button to initiate fabrication.
   * @returns {component} - A button component
   */
  displayInitiateFabricationBtn = () => {
    return <div className="top-right-button">{this.getActionButton('initiate_fabrication', 'Initiate Wire Fabrication Review')}</div>;
  };

  /**
   * Displays elements for PDF viewer, including download link.
   * @param {object} upload_data - Data for the uploaded file
   * @param {string} modified_date - Date when the file was modified
   * @param {string} download_text - Text to display for the download link
   * @returns {component} - PDF viewer components
   */
  displayPDFViewerElems = (upload_data, modified_date, download_text) => {
    return (
      <>
        <span
          className="download-link"
          onClick={() => {
            this.displayPDF(upload_data, modified_date, download_text);
          }}
        >
          {download_text}
          <i className="fa fa-file-pdf-o" aria-hidden="true" />
        </span>
      </>
    );
  };

  /**
   * Helper function to return a formatted custom wire work order box.
   *
   * @param  {String} title Title of the work order box.
   * @param  {Object} body  JSX content to be placed in the body of the work order box.
   * @return {JSX} Returns a JSX of a formatted custom wire work order box.
   */
  getCustomWOBox = (title, body) => (
    <div className="center-text">
      <div className="custom-wo-box">
        <div className="ir process-box-title center-text bold-text">{title}</div>
        <div className="process-box-content">{body}</div>
      </div>
    </div>
  );

  /**
   * Helper function to return an upload button.
   *
   * @return {JSX} Returns a JSX of an upload button.
   */
  getUploadButton = () => (
    <button className="transparent-button lg" onClick={() => this.onUploadClick('ws_custom_wo')}>
      Upload
    </button>
  );

  /**
   * Helper function to return the body content for a work order with a filename.
   *
   * @param {boolean} is_custom_wo_status - Indicates if the current status is custom work order status.
   * @param {string} download_text - The download text to be displayed.
   * @returns {JSX} Returns JSX of the body content for a work order with a filename.
   */
  getFileNameBody = (is_custom_wo_status, download_text) => (
    <div className={`file-container${is_custom_wo_status ? ' centered' : ''}`}>
      {this.displayPDFViewerElems(this.state.custom_wo_file?.upload_data, this.state.custom_wo_file?.modified_date, download_text)}
      {is_custom_wo_status && (
        <i
          className="fa fa-trash remove-icon"
          aria-hidden="true"
          onClick={() => this.setState({ remove_file: { file_type: 'ws_custom_wo', file: this.state.custom_wo_file } })}
        />
      )}
    </div>
  );

  /**
   * Function to display a custom work order.
   *
   * Based on the status of the work order, this function returns different JSX.
   * It either prompts the user to upload a work order file, or it displays the uploaded work order file.
   * If there's no custom work order status or uploaded file, it returns null.
   *
   * @return {JSX|null} Returns the JSX to be rendered for the custom work order or null if no custom work order status or file.
   */
  displayCustomWO = () => {
    const file_name = this.getFileName(this.state.custom_wo_file?.upload_data);
    const is_custom_wo_status = WS_CUSTOM_WO_STATUS === this.state.ws_automation_status;
    let wo_ref = file_name ? file_name.match(/WO-(.*?)\.pdf/) : '';
    wo_ref = wo_ref ? wo_ref[1] : '';

    if (is_custom_wo_status) {
      const title = 'Custom Wire WO';
      const body = file_name ? (
        this.getFileNameBody(is_custom_wo_status, `Custom Wire WO (WO-${wo_ref})`)
      ) : (
        <>
          <div className="process-box-content">Please upload the WO</div>
          {this.getUploadButton()}
        </>
      );
      return this.getCustomWOBox(title, body);
    } else if (SHOW_WO_BY_STATUS.includes(this.state.ws_automation_status)) {
      const title = (
        <span>
          Custom Wire WO Ref:
          <span style={{ fontWeight: 'normal' }}>{wo_ref ? ' WO-' + wo_ref : ' N/A'}</span>
        </span>
      );
      const body = this.getFileNameBody(is_custom_wo_status, 'Custom Wire WO');
      return (
        <>
          <div className="ir bold-text">{title}</div>
          {wo_ref ? body : ''}
        </>
      );
    }
    return null;
  };

  /**
   * Displays an error message for file upload failure.
   * @returns {component} - An error message component or null if no errors are present
   */
  displayFileUploadError = () => {
    return this.props.dxf_file_name_error.case_id ? (
      <div className="dxf-error">Item Request ID Mismatch</div>
    ) : this.props.dxf_file_name_error.format ? (
      <div className="dxf-error">Incorrect File Naming Convention</div>
    ) : this.props.ws_guide_file_name_error ? (
      <div className="dxf-error">
        Incorrect filename - Filename should be: {removeIreqIdInitialNumber(this.props?.item_request?.ireq_id || '')} - Wire Selection Guide Form
      </div>
    ) : this.props.custom_wo_file_name_error.error ? (
      <div className="dxf-error">
        {this.props.custom_wo_file_name_error.error_type === 'format'
          ? `Filename should be ${removeIreqIdInitialNumber(this.props?.item_request?.ireq_id || '')} - WO-XXXXX`
          : this.props.custom_wo_file_name_error.error_type === 'case_id'
          ? 'Item Request ID Mismatch'
          : this.props.custom_wo_file_name_error.error_type === 'length'
          ? 'Max WO Length is 20 Chars'
          : 'Duplicate WO Number'}
      </div>
    ) : null;
  };

  getWireIndex(wireName) {
    const wire = this.state.items.find((item) => item.original_wire_name === wireName);
    return wire ? this.state.items.indexOf(wire) : 0;
  }

  render() {
    return (
      <>
        <div className="bold-text business-dark-heading">Wire Selection</div>
        <div className="business-dark-theme">
          {this.state.display_wire_selection ? (
            this.displayPreviousWireSelection()
          ) : (
            <>
              <div className="bpp-cozy-text-headlines">
                {WS_CONVERTING_STATUSES.includes(this.state.ws_automation_status) ? this.displayConversion() : this.displayItemsByStatus()}
              </div>
              {this.displayFileUploadError()}
              {this.state.remove_file && this.displayModalRemove()}
              {this.state.action_modal && this.displayModalAction()}
              {this.state.upload_modal && this.displayUploadModal()}
              {this.state.modal && this.displayModal()}
            </>
          )}
        </div>
        {this.anyFabricationStatus() && (
          <FabricationWorkflow
            items={this.state.ws_data.ws_details}
            ireq_id={this.props.item_request.ireq_id}
            status={this.state.ws_automation_status}
            ws_data={this.state?.ws_data}
            refreshWSWorkflow={this.refreshWSWorkflow}
          />
        )}
        {this.state.refresh && <SessionExpire />}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dxf_file_name_error: getDxfFileNameError(state),
    ws_guide_file_name_error: getWsGuideFileNameError(state),
    custom_wo_file_name_error: getCustomWoFileNameError(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setDxfFileNameError: setDxfFileNameError,
      setWsGuideFileNameError: setWsGuideFileNameError,
      setCustomWoFileNameError: setCustomWoFileNameError,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WSWorkflow);
